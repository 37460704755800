<template>
  <b-button
    class="btn-icon btn-light btn-hover-primary"
    size="sm"
    :to="to"
    :title="title"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg :src="editIcon" :title="title" />
    </span>
  </b-button>
</template>

<script>
export default {
  name: "KTEditMiniButton",
  props: {
    to: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: "Editar"
    };
  },
  computed: {
    editIcon() {
      return process.env.BASE_URL + "media/svg/icons/General/Edit.svg";
    }
  }
};
</script>
