<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title font-weight-bolder font-size-h3">
        <h3 class="card-label">
          <i
            class="la icon-2x text-secondary mr-2"
            v-if="page.icon"
            :class="page.icon"
          ></i>
          {{ page.title }}
        </h3>
      </div>
      <div class="card-toolbar" v-if="hasToolbarSlot">
        <slot name="toolbar"></slot>
      </div>
    </div>

    <component :is="isForm ? 'form' : 'div'" @submit.stop.prevent="onSubmit">
      <div class="card-body d-flex flex-column">
        <p class="mb-6" v-if="page.description">{{ page.description }}</p>
        <slot name="body"></slot>
      </div>
      <div class="card-footer text-right" v-if="hasFooterSlot">
        <slot name="footer"></slot>
        <template v-if="isForm && !readonly">
          <b-btn
            type="submit"
            :variant="submitButton.variant"
            size="lg"
            class="font-weight-bolder font-size-sm mr-2"
            :disabled="isLoading"
          >
            {{ submitButton.text }}
          </b-btn>
          <b-btn
            variant="secondary"
            size="lg"
            class="font-weight-bolder font-size-sm"
            :to="cancelTo"
          >
            Cancelar
          </b-btn>
        </template>
      </div>
    </component>
  </div>
</template>

<script>
export default {
  name: "KTCard",
  props: {
    page: Object,
    isForm: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    cancelTo: Object,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  methods: {
    onSubmit(event) {
      this.$emit("submit", event);
    }
  },
  computed: {
    hasToolbarSlot() {
      return !!this.$slots["toolbar"];
    },
    hasFooterSlot() {
      return !!this.$slots["footer"] || (this.isForm && !this.readonly);
    },
    submitButton() {
      return this.isEdit
        ? { variant: "warning", text: "Editar" }
        : { variant: "primary", text: "Cadastrar" };
    }
  }
};
</script>
