import ApiService from "@/core/services/api.service.js";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/store/htmlclass.module.js";

const delayLoadingResolve = 600;

const ApiMixin = {
  data() {
    return {
      ApiIsLoading: false
    };
  },
  methods: {
    ApiGetPageData(requests, resolveFunction) {
      // setLoading
      this.ApiIsLoading = true;
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      Promise.all(requests)
        .then((res) => {
          setTimeout(() => {
            // setLoading
            this.ApiIsLoading = false;
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }, delayLoadingResolve);

          resolveFunction(res);
        })
        .catch(() => {
          setTimeout(() => {
            // setLoading
            this.ApiIsLoading = false;
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }, delayLoadingResolve);
        });
    },
    ApiRemoveItem(id) {
      if (!this.ApiIsLoading) {
        // set spinner to submit button
        this.ApiIsLoading = true;
        this.Api.RemoveItem(id)
          .then(() => {
            this.ApiIsLoading = false;
            this.getPageData();
          })
          .catch(() => {
            this.ApiIsLoading = false;
          });
      }
    },
    ApiSubmit(id, form) {
      if (!this.ApiIsLoading) {
        // set spinner to submit button
        this.ApiIsLoading = true;
        let req = undefined;

        if (!this.isEdit) {
          req = this.Api.Add(form);
        } else {
          req = this.Api.Update(id, form);
        }

        req
          .then(() => {
            this.$router.push(this.parentRoute);
            this.ApiIsLoading = false;
          })
          .catch(() => {
            this.ApiIsLoading = false;
          });
      }
    },
    ApiSubmitAdd(form, route = null) {
      if (!this.ApiIsLoading) {
        // set spinner to submit button
        this.ApiIsLoading = true;

        this.Api.Add(form).then((data) => {
          if (route == 'refresh') {
            this.$router.go();
          } else {
            this.$router.push(route ? route(data) : this.parentRoute);
          }
          this.ApiIsLoading = false;
        }).catch(() => {
          this.ApiIsLoading = false;
        });
      }
    },
    ApiSubmitUpdate(id, form, route = null) {
      if (!this.ApiIsLoading) {
        // set spinner to submit button
        this.ApiIsLoading = true;

        this.Api.Update(id, form).then(() => {
          if (route == 'refresh') {
            this.$router.go();
          } else {
            this.$router.push(route ? route : this.parentRoute);
          }
          this.ApiIsLoading = false;
        }).catch(() => {
          this.ApiIsLoading = false;
        });
      }
    },
    ApiGetFile(slug) {
      if (!this.ApiIsLoading) {
        // set spinner to submit button
        this.ApiIsLoading = true;

        this.Api.GetFile(slug).then((data) => {
          const url = window.URL.createObjectURL(new Blob([data], { type: data.type }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'resultado.docx');
          document.body.appendChild(link);
          link.click();
          link.remove();

          this.ApiIsLoading = false;
        }).catch(() => {
          this.ApiIsLoading = false;
        });
      }
    }
  },
  created() {
    this.Api = ApiService.getMethods(this.apiResource);
  }
}

export default ApiMixin;
