<template>
  <b-button
    class="btn-icon btn-light btn-hover-primary"
    size="sm"
    @click="(e) => $emit('click', e)"
    :title="title"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg :src="removeIcon" :title="title" />
    </span>
  </b-button>
</template>

<script>
export default {
  name: "KTRemoveMiniButton",
  data() {
    return {
      title: "Remover"
    };
  },
  computed: {
    removeIcon() {
      return process.env.BASE_URL + "media/svg/icons/General/Trash.svg";
    }
  }
};
</script>
