<template>
  <Vue2Dropzone
    :id="dz_id"
    :options="c_options"
    v-if="options.url"
    @vdropzone-success="vsuccess"
    @vdropzone-removed-file="vremoved"
    @vdropzone-duplicate-file="vdduplicate"
    :duplicateCheck="true"
    :useCustomSlot="true"
  >
    <span v-if="options.maxFiles == 1">
      Arraste o arquivo aqui para fazer o upload.
    </span>
    <span v-else>
      Arraste até {{ options.maxFiles }} arquivos aqui para fazer o upload.
    </span>
    <div>
      <small>Tamanho máximo: {{ options.maxFilesize }}MB.</small><br />
      <small>Arquivos permitidos: {{ options.acceptedFiles }}.</small>
    </div>
  </Vue2Dropzone>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import MsgService from "@/core/services/msg.service";

export default {
  name: "KTDropzone",
  props: {
    dz_id: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    value: {
      type: Array
    }
  },
  data() {
    return {
      files: []
    };
  },
  computed: {
    c_options() {
      return {
        ...this.options,
        addRemoveLinks: true,
        headers: {
          Authorization: JwtService.getToken()
        }
      };
    }
  },
  methods: {
    vsuccess(file, response) {
      let fileUpload = response.response.data;
      fileUpload.uuid = file.upload.uuid;
      this.files.push(fileUpload);
    },
    vremoved(file) {
      for (var i = 0; i < this.files.length; i++) {
        if (this.files[i].uuid == file.upload.uuid) {
          this.files.splice(i, 1);
        }
      }
    },
    vdduplicate() {
      MsgService.show({
        title: "Erro ao adicionar arquivo",
        description:
          "Um arquivo duplicado foi adicionado. Não é permitido arquivos duplicados.",
        type: "warning"
      });
    }
  },
  watch: {
    files() {
      // Fazendo v-model funcionar
      this.$emit("input", this.files);
    }
  }
};
</script>
