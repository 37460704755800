<template>
  <TheMask
    class="form-control"
    :class="sizeClass"
    :mask="mask_c.mask"
    :placeholder="mask_c.placeholder"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :value="value"
  ></TheMask>
</template>

<script>
// Local Component
import { TheMask } from "vue-the-mask";

export default {
  name: "KTInputMask",
  props: {
    value: {
      default: null
    },
    size: {
      type: String,
      default: "lg"
    },
    mask: {
      type: [String, Array],
      required: true
    },
    placeholder: {
      type: String
    }
  },
  data() {
    return {
      masks: {
        CPF: {
          mask: "###.###.###-##",
          placeholder: ""
        },
        CNPJ: {
          mask: "##.###.###/####-##",
          placeholder: ""
        },
        CEP: {
          mask: "#####-###",
          placeholder: ""
        },
        data: {
          mask: "##/##/####",
          placeholder: "dd/mm/aaaa"
        },
        hora: {
          mask: "##:##:##",
          placeholder: "hh:mm:ss"
        },
        data_hora: {
          mask: "##/##/#### ##:##:##",
          placeholder: " dd/mm/aaaa hh:mm:ss"
        },
        telefone: {
          mask: ["(##) ####-####", "(##) #####-####"],
          placeholder: ""
        }
      }
    };
  },
  components: {
    TheMask
  },
  computed: {
    mask_c() {
      if (
        typeof this.mask == "string" &&
        typeof this.masks[this.mask] != "undefined"
      ) {
        return this.masks[this.mask];
      } else {
        return {
          mask: this.mask,
          placeholder: this.placeholder
        };
      }
    },
    sizeClass() {
      return this.size ? `form-control-${this.size}` : "";
    }
  }
};
</script>
