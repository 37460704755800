<template>
  <b-form-select
    :size="size"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :required="required"
    :readonly="readonly"
    :disabled="disabled"
    :options="options"
  >
    <template #first>
      <b-form-select-option :value="null">Selecione...</b-form-select-option>
    </template>
  </b-form-select>
</template>

<script>
export default {
  name: "KTSelect",
  props: {
    value: {
      default: null
    },
    size: {
      type: String,
      default: "lg"
    },
    options: {
      type: Array,
      required: true
    },
    required: Boolean,
    disabled: Boolean,
    readonly: Boolean
  }
};
</script>
