<template>
  <b-button
    variant="success"
    size="lg"
    class="font-weight-bolder font-size-sm"
    :to="to"
    :title="title"
  >
    <span class="svg-icon svg-icon-md svg-icon-white">
      <inline-svg :src="addIcon" :title="title" />
    </span>
    Adicionar
  </b-button>
</template>

<script>
export default {
  name: "KTAddButton",
  props: {
    to: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: "Adicionar"
    };
  },
  computed: {
    addIcon() {
      return process.env.BASE_URL + "media/svg/icons/Navigation/Plus.svg";
    }
  }
};
</script>
