<template>
  <CurrencyInput
    class="form-control"
    :class="sizeClass"
    :value-range="valueRange"
    :precision="precision"
    :locale="locale"
    :currency="currency"
    :distraction-free="distractionFree"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :value="value"
  ></CurrencyInput>
</template>

<script>
import { CurrencyInput } from "vue-currency-input";

export default {
  name: "KTInputNumeric",
  props: {
    value: {
      default: null
    },
    size: {
      type: String,
      default: "lg"
    },
    precision: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER
    }
  },
  data() {
    return {
      locale: "nl-NL",
      distractionFree: false,
      currency: null
    };
  },
  components: {
    CurrencyInput
  },
  computed: {
    valueRange() {
      return { min: this.min, max: this.max };
    },
    sizeClass() {
      return this.size ? `form-control-${this.size}` : "";
    }
  }
};
</script>
