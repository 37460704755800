<template>
  <div>
    <b-row align-h="between">
      <b-col md="6" lg="4" class="mb-2">
        <b-button variant="outline-primary" @click="exportar()">
          <i class="far fa-file-excel"></i>
          Exportar
        </b-button>
      </b-col>
      <b-col md="6" lg="4" class="mb-2" v-if="searchable">
        <b-form-input
          v-model="filter"
          type="search"
          placeholder="Pesquisar"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-table
      :filter="filter"
      :fields="fields"
      :items="items"
      :primary-key="primaryKey"
      :sort-by="sortBy"
      :empty-text="emptyText"
      :empty-filtered-text="emptyFilteredText"
      :current-page="paginated ? pagination.currentPage : 1"
      :per-page="paginated ? pagination.perPage : 0"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      show-empty
      outlined
      head-variant="light"
      responsive
      bordered
      class="position-relative"
      :thead-tr-class="theadTrClass"
    >
      <template v-slot:[s]="data" v-for="s in slots">
        <slot :name="s" v-bind="data"></slot>
      </template>
    </b-table>
    <b-row v-if="paginated">
      <b-col sm="8" md="7" lg="6" class="my-1">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
      <b-col sm="4" md="3" lg="2" offset-md="2" offset-lg="4" class="my-1">
        <b-form-group
          label="Por página"
          label-for="per-page-select"
          label-cols-sm="9"
          label-cols-md="8"
          label-cols-lg="7"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            v-model="pagination.perPage"
            :options="pagination.pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "KTTable",
  props: {
    fields: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    primaryKey: {
      type: String,
      default: null
    },
    sortBy: {
      type: String,
      required: true
    },
    paginated: {
      type: Boolean,
      default: true
    },
    searchable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filter: null,
      emptyText: "Não há registros para mostrar",
      emptyFilteredText: "Não há registros correspondentes à sua solicitação",
      pagination: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, 100]
      },
      theadTrClass: "text-uppercase text-center"
    };
  },
  computed: {
    filterOn() {
      // Create an options list from our fields
      return this.fields.reduce((a, c) => {
        if (c.filterable) a.push(c.key);
        return a;
      }, []);
    },
    slots() {
      return this.$scopedSlots ? Object.keys(this.$scopedSlots) : [];
    },
    exportFields() {
      return this.fields.filter((item) => item.key[0] != "_");
    },
    exportData() {
      let rows = [];
      let header = this.exportFields.map((item) => item.label);
      rows.push(header);

      this.items.forEach((item) => {
        rows.push(
          this.exportFields.reduce((pre, cur) => {
            pre.push(item[cur.key]);
            return pre;
          }, [])
        );
      });

      return rows;
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.pagination.totalRows = filteredItems.length;
      this.pagination.currentPage = 1;
    },
    number(value) {
      return !isNaN(value) && value !== null && value !== ""
        ? value.toLocaleString("pt-br", {
            maximumFractionDigits: 2
          })
        : value;
    },
    currency(value) {
      return !isNaN(value) && value !== null && value !== ""
        ? value.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        : value;
    },
    exportar() {
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Exportacao",
        Subject: "Exportacao",
        Author: "PAEF",
        CreatedDate: new Date()
      };

      wb.SheetNames.push("Planilha 1");

      var ws = XLSX.utils.aoa_to_sheet(this.exportData);

      wb.Sheets["Planilha 1"] = ws;

      XLSX.writeFile(wb, "paef_table_export.xlsx");
    }
  },
  watch: {
    items: function (val) {
      // Set the number of items
      this.pagination.totalRows = val.length;
      this.pagination.currentPage = 1;
    }
  },
  mounted() {
    // Set the initial number of items
    this.pagination.totalRows = this.items.length;
  }
};
</script>

<style lang="scss" :scoped="true">
.table thead th {
  vertical-align: middle;
}
</style>
