import { SET_BACKTO } from "@/core/store/breadcrumbs.module";
import { INIT_MANUAL, RESET_MANUAL } from "@/core/store/manual.module";
import KTCard from "@/view/components/Card.vue";
import KTTable from "@/view/components/Table.vue";
import KTDropzone from "@/view/components/form/Dropzone.vue";
import KTSelect from "@/view/components/form/Select.vue";
import KTInputNumeric from "@/view/components/form/InputNumeric.vue";
import KTInputMask from "@/view/components/form/InputMask.vue";
import KTViewButton from "@/view/components/form/ViewButton.vue";
import KTAddButton from "@/view/components/form/AddButton.vue";
import KTEditButton from "@/view/components/form/EditButton.vue";
import KTListMiniButton from "@/view/components/form/ListMiniButton.vue";
import KTViewMiniButton from "@/view/components/form/ViewMiniButton.vue";
import KTEditMiniButton from "@/view/components/form/EditMiniButton.vue";
import KTRemoveMiniButton from "@/view/components/form/RemoveMiniButton.vue";
import Exportar from "@/view/components/Exportar.vue";

const HelpersMixin = {
  components: {
    KTCard,
    KTTable,
    KTDropzone,
    KTSelect,
    KTInputNumeric,
    KTInputMask,
    KTViewButton,
    KTAddButton,
    KTEditButton,
    KTListMiniButton,
    KTViewMiniButton,
    KTEditMiniButton,
    KTRemoveMiniButton,
    Exportar
  },
  computed: {
    listIcon() {
      return process.env.BASE_URL + "media/svg/icons/Text/Menu.svg";
    },
    viewIcon() {
      return process.env.BASE_URL + "media/svg/icons/General/Visible.svg";
    },
    addIcon() {
      return process.env.BASE_URL + "media/svg/icons/Navigation/Plus.svg";
    },
    editIcon() {
      return process.env.BASE_URL + "media/svg/icons/General/Edit.svg";
    },
    removeIcon() {
      return process.env.BASE_URL + "media/svg/icons/General/Trash.svg";
    }
  },
  methods: {
    formatNumber(value) {
      return !isNaN(value) && value !== null && value !== ""
        ? value.toLocaleString("pt-br", { maximumFractionDigits: 2 }) : value;
    },
    formatCurrency(value) {
      return !isNaN(value) && value !== null && value !== ""
        ? value.toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        : value;
    },
    initManual(session, tipo) {
      this.$store.dispatch(INIT_MANUAL, { session, tipo });
    },
    resetManual() {
      this.$store.commit(RESET_MANUAL);
    }
  },
  mounted() {
    if (typeof this.parentRoute !== "undefined") {
      this.$store.dispatch(SET_BACKTO, this.parentRoute);
    }
    this.resetManual();
  }
}

export default HelpersMixin;
